import HeaderImageUrl from "assets/homePageHeader.png";
import { CommonContentBlock, HeaderImage } from "components/ContentBlock";
import { Paragraph, TextLinkA } from "components/Typography";
import React from "react";
import { COLORS } from "components/STYLE_CONFIG";
import CurisButton from "components/CurisButton";
import { Link } from "react-router-dom";
import ContentBlock from "components/ContentBlock";

export const HomePage: React.FC = () => {
    return (
        <React.Fragment>
            <HeaderImage
                imageUrl={HeaderImageUrl}
                gradient="linear-gradient(90deg, #01011B 21.93%, rgba(1, 1, 27, 0) 100%)"
                headerText="Computer Science Research"
                description="This site lists research opportunities for undergraduate
                and masters students in computer science or which apply computer
                science in other fields. It also organizes the CURIS Summer Internship research
                program."
            />

            <ContentBlock color={COLORS.LIGHT_GREY}>
                <div className="container" flex-direction="column">
                    <div className="row">
                        <div className="col-lg-4 text-center">
                            <Link to="/about/">
                                <CurisButton>Getting Started in Research</CurisButton>
                            </Link><br/>
                            <Paragraph> Interested in getting started in research but don't know where to start?&nbsp;
                                Use this guide to learn more about getting started with CS Research.
                            </Paragraph><br/>
                        </div>

                        <div className="col-lg-4 text-center">
                            <Link to="/summer/">
                                <CurisButton>CURIS: Summer CS Internships</CurisButton>
                            </Link><br/>
                            <Paragraph>
                                Learn more about CURIS, the CS summer paid internship in which undergraduate students work with a CS faculty member and their group, towards an identifiable research result.                     
                            </Paragraph><br/>
                        </div>

                        <div className="col-lg-4 text-center">
                            <Link to="/summer_projects/">
                                <CurisButton>CURIS Summer Projects</CurisButton>
                            </Link><br/>
                            <Paragraph>
                                View the 2024 CURIS Summer Project listings. The CURIS student application period is open from February 3 - 17.                 
                            </Paragraph><br/>
                        </div>


                        {/* <div className="col-lg-4 text-center">
                            <Link to="/projects_outside_cs/">
                                <CurisButton>University-wide projects using CS</CurisButton>
                            </Link><br/>
                            <Paragraph>
                                Projects looking for undergraduates or masters students
                                to apply CS in other fields. All faculty in the university
                                are welcome to post projects.
                            </Paragraph><br/>
                        </div> */}
                    </div>
					<br/>
                    <div className="row">
                        <div className="col-lg-4 text-center">

                            <Link to="/year_round_cs_projects/">
                                <CurisButton>Year-round CS Projects</CurisButton>
                            </Link><br/>
                            <Paragraph>
                                View listings for CS department projects seeking undergraduate
                                and master's students during the academic year (for research units, hourly pay, or RA).
                            </Paragraph><br/>
                        </div>

                        <div className="col-lg-4 text-center">
                            <Link to="/curis_fellowships/" target="_blank">
                                <CurisButton>CURIS Fellowships</CurisButton>
                            </Link><br/>
                            <Paragraph>
                            Learn about CURIS Fellowships, which provide guaranteed funding for CURIS summer internships. The fellowships aim to provide early research exposure to a diverse set of students who are new to research.
                            </Paragraph><br/>
                        </div>

                        <div className="col-lg-4 text-center">
                            <Link to="/pure_student/">
                                <CurisButton>PURE: Paid Undergraduate Research Experience</CurisButton>
                            </Link><br/>
                            <Paragraph>
                            Learn about the PURE program, which offers paid research opportunities for FLI students throughout the academic year.<br/>
                            </Paragraph><br/>
                        </div>
                    </div>
                </div>
          </ContentBlock>

        </React.Fragment>
    );
};
