import { Resource } from "rest-hooks";
import { BASE_URL } from "./api";
import AuthdResource from "./AuthdResource";

export default class StudentResource extends AuthdResource {
  readonly gpa: string = "0.00";
  readonly id: string = "";
  readonly is_cs_coterm: boolean = false;
  readonly is_graduating_in_june: boolean = false;
  readonly is_matched: boolean = false;
  readonly is_cs_declared_by_mar1: boolean = false;
  readonly is_curis_fellow: boolean = false;
  readonly major: string = "";
  readonly name: string = "";
  readonly preferred_email: string = "";
  readonly research_interest_area: string = "";
  readonly suid: string = "";
  readonly sunet: string = "";
  readonly user_id: string = "";
  readonly webpage: string = "";
  readonly graduation_year: string = "";
  readonly resume: string | undefined = undefined;
  readonly transcript: string | undefined = undefined;
  readonly is_undergrad_tuition_status: boolean = false;

  pk() {
    return this.user_id;
  }

  get initials() {
    return nameToInitials(this.name);
  }

  /**
   * Get the url for a Resource
   */
  static url<T extends typeof Resource>(
    this: T,
    urlParams: { user_id: string }
  ): string {
    return BASE_URL + `/api/students/?user_id=${urlParams.user_id}`;
  }

  /**
   * Get the url for a Resource
   */
  static listUrl<T extends typeof Resource>(
    this: T,
    urlParams: { user_id: string }
  ): string {
    return BASE_URL + `/api/students/?user_id=${urlParams.user_id}`;
  }
}

function nameToInitials(fullName: string) {
  const namesArray = fullName.split(" ");
  if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
  else
    return `${namesArray[0].charAt(0)}${namesArray[
      namesArray.length - 1
    ].charAt(0)}`;
}
