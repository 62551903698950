import axios from "axios";
import CurisButton from "components/CurisButton";
import { COLORS } from "components/STYLE_CONFIG";
import { Header1, Paragraph, TextLinkA } from "components/Typography";
import React, { useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { BASE_URL } from "resources/api";
import OptionsResource from "resources/options";
import ProfileResource from "resources/profile";
import SettingsResource from "resources/settings";
import UserResource from "resources/user";
import { useFetcher, useResource } from "rest-hooks";
import { Divider, Form, Grid, Loader, Radio, Checkbox } from "semantic-ui-react";
import styled from "styled-components";
import Cookies from "universal-cookie";
import { ErrorScreen } from "./ErrorScreen";

export const PageWrapper = styled.div`
    padding: 0px 140px;
    padding-top: 50px;
    background: white;
`;

interface Values {
    SUID?: string;
    Email?: string;
    Website?: string;
    Interest?: string;
    Major?: string;
    GPA?: string;
}

interface Event<T = EventTarget> {
    target: T;
}

const required = (value: string | undefined) => (value ? undefined : "Required");
const suid = (value: string | undefined) =>
    /^[0-9]{8}/.test(value || "") ? undefined : "Please enter a valid 8 digit SUID";
const email = (value: string | undefined) =>
    /^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(value || "")
        ? undefined
        : "Please enter a valid email address";
const gpa = (value: string | undefined) =>
    /^[0-9]\.[0-9][0-9]$/.test(value || "") ? undefined : "Please enter a valid GPA";
const vmajor = (value: string | undefined) =>
    /^[a-zA-Z0-9_\-\\. ]{2,32}$/.test(value || "") ? undefined : "Please enter a valid major (up to 32 alphanumeric characters and spaces)";

const composeValidators = (...validators: any[]) => (value: string | undefined) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

export const ProfilePage: React.FC<{ summer?: boolean }> = (props) => {
    const [settingsList, user] = useResource(
        [SettingsResource.listShape(), {}],
        [UserResource.detailShape(), {}]
    );
    const settings = settingsList[0];

    if (!user.is_student)
        return (
            <ErrorScreen
                header="Page not available"
                message="The page you were trying to access is only available to students."
                showReturn
            />
        );

    if (!settings.student_edit_profile_enabled)
        return (
            <ErrorScreen
                header="Page not available"
                message="The page you were trying to access isn’t currently available because students do not yet have permission to edit profiles."
                showReturn
            />
        );

    return <ProfilePageContent summer={props.summer} />;
};

const ProfilePageContent: React.FC<{ summer?: boolean }> = (props) => {
    const [profile, options] = useResource(
        [ProfileResource.detailShape(), {}],
        [OptionsResource.detailShape(), {}]
    );
    const update = useFetcher(ProfileResource.partialUpdateShape());
    const [loading, setLoading] = useState(false);

    const onSubmit = async ({ SUID, Email, Website, Interest, Major, GPA }: Values) => {
        setLoading(true);
        await update(
            {},
            {
                suid: SUID,
                preferred_email: Email,
                webpage: Website || "",
                research_interest_area: Interest || "",
                major: Major || "",
                graduation_year: year || "",
                gpa: GPA || "0.00",
                is_graduating_in_june: finishingInJune,
                is_cs_coterm: csCoterm,
                is_cs_declared_by_mar1: declared,
                is_undergrad_tuition_status: undergradTuitionStatus
            }
        );
        const domForm = new FormData();
        if (resume) domForm.append("resume", resume);
        if (transcript) domForm.append("transcript", transcript);
        if (resume || transcript) {
            const cookies = new Cookies();
            await axios.post(BASE_URL + "/api/students/profile/", domForm, {
                headers: {
                    Authorization: `Token ${cookies.get("authToken")}`,
                },
            });
        }
        setLoading(false);
    };

    const [finishingInJune, setFinishingInJune] = useState(profile.is_graduating_in_june);
    const [declared, setDeclared] = useState(profile.is_cs_declared_by_mar1);
    const [csCoterm, setCsCoterm] = useState(profile.is_cs_coterm);
    const [year, setYear] = useState(profile.graduation_year);

    const [undergradTuitionStatus, setUndergradTuitionStatus] = useState(profile.is_undergrad_tuition_status);

    const [resume, setResume] = useState<File | null>(null);

    const handleResumeClick = (event: Event) => {
        document.getElementById("resumeInput")!.click();
    };
    const handleResumeChange = (event: Event<HTMLInputElement>) => {
        const fileUploaded = event!.target!.files![0];
        setResume(fileUploaded);
    };

    const [transcript, setTranscript] = useState<File | null>(null);

    const handleTranscriptClick = (event: Event) => {
        document.getElementById("transcriptInput")!.click();
    };
    const handleTranscriptChange = (event: Event<HTMLInputElement>) => {
        const fileUploaded = event!.target!.files![0];
        setTranscript(fileUploaded);
    };

    return (
        <PageWrapper>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <Divider />
                        <div style={{ display: "flex" }}>
                            <Avatar>
                                <span>{profile.initials}</span>
                            </Avatar>
                            <div>
                                <div>
                                    <Paragraph bold>{profile.name}</Paragraph>
                                </div>
                                <div>
                                    <Paragraph>{profile.preferred_email}</Paragraph>
                                </div>
                                <div>
                                    <Paragraph>{profile.major}</Paragraph>
                                    {profile.graduation_year && ", "}
                                    <Paragraph>{profile.graduation_year}</Paragraph>
                                </div>
                                {profile.resume && (
                                    <div>
                                        <TextLinkA
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={profile.resume}
                                        >
                                            Resume
                                        </TextLinkA>
                                    </div>
                                )}
                                {profile.transcript && (
                                    <div>
                                        <TextLinkA
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={profile.transcript}
                                        >
                                            Transcript
                                        </TextLinkA>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Divider />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={9}>
                        <div style={{ width: "568px" }}>
                            <Header1>My Profile</Header1>
                            <div style={{ marginTop: "30px", marginBottom: "40px" }}>
                                <Paragraph>
                                    This information is shared with faculty leading the projects you
                                    apply to for review as part of our matching process.
                                </Paragraph>
                                <Form
                                    style={{ marginTop: "40px" }}
                                    // onSubmit={handleSubmit((data) => console.log(data))}
                                >
                                    <StyledInput
                                        name="Name"
                                        fluid
                                        type="text"
                                        label="Name*"
                                        disabled
                                        value={profile.name}
                                    />
                                    <StyledInput
                                        name="SUNet"
                                        fluid
                                        type="text"
                                        label="Your SUNet ID*"
                                        disabled
                                        value={profile.sunet}
                                    />
                                    <FinalForm
                                        onSubmit={onSubmit}
                                        initialValues={{
                                            SUID: profile.suid,
                                            Email: profile.preferred_email,
                                            Website: profile.webpage,
                                            Interest: profile.research_interest_area,
                                            Major: profile.major,
                                            GPA: profile.gpa,
                                        }}
                                        render={({
                                            handleSubmit,
                                            form,
                                            submitting,
                                            pristine,
                                            values,
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                <Field
                                                    name="SUID"
                                                    validate={composeValidators(required, suid)}
                                                >
                                                    {({ input, meta }) => (
                                                        <div style={{ marginBottom: "18px" }}>
                                                            <StyledInput
                                                                name={input.name}
                                                                label="Your SUID (8 digits)*"
                                                                value={input.value}
                                                                onChange={input.onChange}
                                                                error={
                                                                    meta.error &&
                                                                    meta.touched &&
                                                                    meta.error
                                                                }
                                                                maxLength={8}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <Field
                                                    name="Email"
                                                    validate={composeValidators(required, email)}
                                                >
                                                    {({ input, meta }) => (
                                                        <div style={{ marginBottom: "18px" }}>
                                                            <StyledInput
                                                                name={input.name}
                                                                label="Your Preferred Email*"
                                                                value={input.value}
                                                                onChange={input.onChange}
                                                                error={
                                                                    meta.error &&
                                                                    meta.touched &&
                                                                    meta.error
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <Field name="Website">
                                                    {({ input, meta }) => (
                                                        <div style={{ marginBottom: "18px" }}>
                                                            <StyledInput
                                                                name={input.name}
                                                                label="Personal Website"
                                                                value={input.value}
                                                                onChange={input.onChange}
                                                                error={
                                                                    meta.error &&
                                                                    meta.touched &&
                                                                    meta.error
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <Field name="Interest">
                                                    {({ input, meta }) => (
                                                        <div style={{ marginBottom: "18px" }}>
                                                            <StyledInput
                                                                name={input.name}
                                                                label="Research Interest Area"
                                                                value={input.value}
                                                                onChange={input.onChange}
                                                                error={
                                                                    meta.error &&
                                                                    meta.touched &&
                                                                    meta.error
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <Field name="Major" validate={composeValidators(required, vmajor)}>
                                                    {({ input, meta }) => (
                                                        <div style={{ marginBottom: "18px" }}>
                                                            <StyledInput
                                                                name={input.name}
                                                                label="Major*"
                                                                value={input.value}
                                                                onChange={input.onChange}
                                                                error={
                                                                    meta.error &&
                                                                    meta.touched &&
                                                                    meta.error
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <StyledSelect
                                                    name="Year"
                                                    options={options.graduation_year_options}
                                                    placeholder="Select a graduation year"
                                                    label="Graduation Year"
                                                    value={year}
                                                    onChange={(
                                                        e: any,
                                                        {
                                                            name,
                                                            value,
                                                        }: { name: string; value: string }
                                                    ) => {
                                                        setYear(value);
                                                    }}
                                                />
                                                <Field name="GPA" validate={gpa}>
                                                    {({ input, meta }) => (
                                                        <div style={{ marginBottom: "18px" }}>
                                                            <StyledInput
                                                                name={input.name}
                                                                label="GPA in CS / Math Courses"
                                                                value={input.value}
                                                                onChange={input.onChange}
                                                                type="number"
                                                                min="0"
                                                                // maxLength={3}
                                                                step="any"
                                                                style={{ width: "176px" }}
                                                                error={
                                                                    meta.error &&
                                                                    meta.touched && {
                                                                        content:
                                                                            meta.error &&
                                                                            meta.touched &&
                                                                            meta.error,
                                                                        pointing: "left",
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                <Form.Field>
                                                    <div
                                                        style={{
                                                            marginBottom: "17px",
                                                            marginTop: "32px",
                                                        }}
                                                    >
                                                        <Paragraph bold>
                                                            Will you be finishing your degree by
                                                            June?
                                                        </Paragraph>
                                                    </div>
                                                    <StyledRadio
                                                        label="Yes"
                                                        name="FinishingInJune"
                                                        value="Yes"
                                                        checked={finishingInJune}
                                                        onClick={() => {
                                                            setFinishingInJune(true);
                                                        }}
                                                    />
                                                    <StyledRadio
                                                        label="No"
                                                        name="FinishingInJune"
                                                        value="No"
                                                        style={{ marginLeft: "34px" }}
                                                        checked={!finishingInJune}
                                                        onClick={() => {
                                                            setFinishingInJune(false);
                                                        }}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <div
                                                        style={{
                                                            marginBottom: "17px",
                                                            marginTop: "32px",
                                                        }}
                                                    >
                                                        <Paragraph bold>
                                                            Will you be a declared CS major by March
                                                            1?
                                                        </Paragraph>
                                                    </div>
                                                    <StyledRadio
                                                        label="Yes"
                                                        name="Declared"
                                                        value="Yes"
                                                        checked={declared}
                                                        onClick={() => {
                                                            setDeclared(true);
                                                        }}
                                                    />
                                                    <StyledRadio
                                                        label="No"
                                                        name="Declared"
                                                        value="No"
                                                        style={{ marginLeft: "34px" }}
                                                        checked={!declared}
                                                        onClick={() => {
                                                            setDeclared(false);
                                                        }}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <div
                                                        style={{
                                                            marginBottom: "17px",
                                                            marginTop: "32px",
                                                        }}
                                                    >
                                                        <Paragraph bold>
                                                            Are you a current CS coterm?
                                                        </Paragraph>
                                                    </div>
                                                    <StyledRadio
                                                        label="Yes"
                                                        name="CsCoterm"
                                                        value="Yes"
                                                        checked={csCoterm}
                                                        onClick={() => {
                                                            setCsCoterm(true);
                                                        }}
                                                    />
                                                    <StyledRadio
                                                        label="No"
                                                        name="CsCoterm"
                                                        value="No"
                                                        style={{ marginLeft: "34px" }}
                                                        checked={!csCoterm}
                                                        onClick={() => {
                                                            setCsCoterm(false);
                                                        }}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <div
                                                        style={{
                                                            marginBottom: "17px",
                                                            marginTop: "32px",
                                                        }}
                                                    >
                                                    <Paragraph bold>
                                                        You must have undergraduate tuition status next summer to be eligible for CURIS. Please use <a href="https://docs.google.com/forms/d/e/1FAIpQLSdLv4RpI3IGbfhAAqXgVvnqJXMJCLSh1CzrHUTnUa-LuOmM3g/viewform?usp=sf_link" target="_blank">this form</a> to verify whether you will have undergraduate tuition status next summer. Have you verified that you will have undergraduate tuition status for the upcoming summer?
                                                    </Paragraph>
                                                    </div>
                                                <StyledRadio
                                                        label="Yes"
                                                        name="UndergradTuitionStatus"
                                                        value="Yes"
                                                        checked={undergradTuitionStatus}
                                                        onClick={() => {
                                                            setUndergradTuitionStatus(true);
                                                        }}
                                                    />
                                                    <StyledRadio
                                                        label="No"
                                                        name="UndergradTuitionStatus"
                                                        value="No"
                                                        style={{ marginLeft: "34px" }}
                                                        checked={!undergradTuitionStatus}
                                                        onClick={() => {
                                                            setUndergradTuitionStatus(false);
                                                        }}
                                                    />
                                                </Form.Field>
                                                <UploadWrapper>
                                                    <div>
                                                        <div>
                                                            <Paragraph bold>Resume</Paragraph>
                                                        </div>
                                                        {resume ? (
                                                            <Paragraph>{resume.name}</Paragraph>
                                                        ) : profile.resume ? (
                                                            <TextLinkA
                                                                href={profile.resume}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Resume
                                                            </TextLinkA>
                                                        ) : (
                                                            <Paragraph>Missing</Paragraph>
                                                        )}
                                                    </div>
                                                    <CurisButton
                                                        size="small"
                                                        type="submit"
                                                        onClick={handleResumeClick}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Upload New
                                                    </CurisButton>
                                                    <input
                                                        type="file"
                                                        id="resumeInput"
                                                        onChange={handleResumeChange}
                                                        style={{ display: "none" }}
                                                    />
                                                </UploadWrapper>
                                                <UploadWrapper>
                                                    <div>
                                                        <div>
                                                            <Paragraph bold>Transcript</Paragraph>
                                                        </div>
                                                        {transcript ? (
                                                            <Paragraph>{transcript.name}</Paragraph>
                                                        ) : profile.transcript ? (
                                                            <TextLinkA
                                                                href={profile.transcript}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Transcript
                                                            </TextLinkA>
                                                        ) : (
                                                            <Paragraph>
                                                                Missing Transcript
                                                            </Paragraph>
                                                        )}
                                                    </div>
                                                    <CurisButton
                                                        size="small"
                                                        type="submit"
                                                        onClick={handleTranscriptClick}
                                                    >
                                                        Upload New
                                                    </CurisButton>
                                                    <input
                                                        type="file"
                                                        id="transcriptInput"
                                                        onChange={handleTranscriptChange}
                                                        style={{ display: "none" }}
                                                    />
                                                </UploadWrapper>
                                                <CurisButton
                                                    type="submit"
                                                    style={{ marginTop: "30px" }}
                                                    // style={{ marginTop: "60px" }}
                                                    disabled={loading}
                                                >
                                                    {!loading ? (
                                                        "Update Profile"
                                                    ) : (
                                                        <Loader
                                                            active
                                                            inline
                                                            size="mini"
                                                            inverted
                                                        />
                                                    )}
                                                </CurisButton>
                                            </form>
                                        )}
                                    />
                                </Form>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </PageWrapper>
    );
};

const UploadWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Avatar = styled.div`
    margin-right: 21px;
    width: 78px;
    height: 78px;
    background-color: ${COLORS.DARK_RED};
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    & span {
        position: relative;
        font-size: 38px;
        line-height: 50px;
        color: #fff;
        font-family: Lato;
        font-weight: bold;
    }
`;

const StyledInput = styled(Form.Input)`
    font-family: Lato !important;
    font-size: 18px !important;
    line-height: 29px !important;
    font-weight: 600 !important;
    color: ${COLORS.DARK_GREY} !important;

    & label {
        font-family: Lato !important;
        font-size: 18px !important;
        line-height: 29px !important;
        font-weight: 600 !important;
    }

    & input {
        padding: 13px !important;
    }
`;

const StyledSelect = styled(Form.Select)`
    font-family: Lato !important;
    font-size: 18px !important;
    line-height: 29px !important;
    // font-weight: 600 !important;
    color: ${COLORS.DARK_GREY} !important;

    & label {
        font-family: Lato !important;
        font-size: 18px !important;
        line-height: 29px !important;
        // font-weight: 600 !important;
    }

    & input {
        padding: 13px !important;
    }
`;

const StyledRadio = styled(Radio)`
    font-family: Lato !important;
    font-size: 18px !important;

    & label {
        height: 25px !important;
        display: flex !important;
        align-items: center !important;
    }
    & label::before {
        width: 25px !important;
        height: 25px !important;
        background-color: ${COLORS.MED_GREY} !important;
    }

    & label::after {
        width: 25px !important;
        height: 25px !important;
        background-color: #006cb8 !important;
        transform: scale(0.7) !important;
    }
`;

const StyledCheckbox = styled(Checkbox)`
    font-family: Lato !important;
    font-size: 18px !important;

    & label {
        height: 25px !important;
        display: flex !important;
        align-items: center !important;
    }
    & label::before {
        width: 25px !important;
        height: 25px !important;
        background-color: ${COLORS.MED_GREY} !important;
    }

    & label::after {
        width: 25px !important;
        height: 25px !important;
        background-color: #006cb8 !important;
        transform: scale(0.7) !important;
    }
`;
